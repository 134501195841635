// @mui icons

// Material Kit 2 React components
import config from "config";

export const footer = {
  brand: {
    image: config.logo,
    route: "/",
  },
  socials: [],
  menus: [
    {
      name: "info",
      items: [
        { name: "contact", route: "/contact" },
        { name: "avis", route: "/nouvel-avis" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "mentions-legales", route: "/mentions-legales" },
        { name: "politique-confidentialite", route: "/politique-de-confidentialite" },
        { name: "cgu", route: "/conditions-generales-utilisation" },
        { name: "politique-cookies", route: "/politique-cookies" },
      ],
    },
  ],
};
