export const article = `
Bienvenue à l'**UAPE Pas à Pas**, une structure de garde d’enfants qui place le bien-être et le développement de chaque enfant au cœur de ses priorités.  

## Notre Équipe

Notre équipe est composée de professionnelles passionnées et qualifiées qui s'engagent à offrir un environnement chaleureux et stimulant à vos enfants :

- **Une enseignante primaire** expérimentée, dédiée à l'accompagnement des enfants dans leurs premiers apprentissages, tout en s'adaptant au rythme de chacun.
- **Des éducatrices spécialisées**, attentives aux besoins spécifiques de chaque enfant et expertes dans la création d’activités adaptées pour favoriser l’épanouissement individuel.

## Nos Valeurs

A l'**UAPE Pas à Pas**, nous croyons que chaque enfant est unique et mérite un accompagnement personnalisé pour l’aider à grandir et à s’épanouir. Voici les valeurs qui guident notre quotidien :

- **Bienveillance** : Chaque enfant est accueilli avec respect, écoute et empathie. Nous valorisons l’encouragement positif pour construire une confiance en soi durable.
- **Apprendre par le jeu** : Le jeu est au cœur de nos activités éducatives. Nous croyons fermement que c’est en explorant, en expérimentant et en s’amusant que les enfants développent leurs compétences et leur curiosité.
- **L’unicité de chaque enfant** : Nous respectons les différences, les besoins et les rythmes propres à chaque enfant, tout en les accompagnant pour qu’ils grandissent en harmonie.

## Notre Engagement

Nous sommes convaincus que la petite enfance est une période cruciale pour le développement émotionnel, social et intellectuel des enfants. Notre objectif est de leur offrir un cadre sécurisant, stimulant et joyeux, où ils pourront s’épanouir à leur rythme et construire les bases solides pour leur avenir.

Merci de nous faire confiance pour accompagner vos enfants dans leurs premiers pas vers l’autonomie et l’apprentissage.

L’équipe **UAPE Pas à Pas**

`