export const content = `# Conditions Générales d'Utilisation

**Accès au Site**  
Le site de **<span class="name"></span>** est accessible gratuitement à tout utilisateur ayant un accès à Internet.

**Services Proposés**  
Le site propose des informations sur les services de garde d’enfants et permet de contacter les responsables ainsi que de procéder à une inscription en ligne.

**Limitation de Responsabilité**  
**<span class="name"></span>** s’efforce de fournir des informations fiables. Toutefois, elle ne peut garantir l'exactitude des informations publiées et décline toute responsabilité quant aux erreurs ou omissions.

**Modification des CGU**  
La garderie se réserve le droit de modifier les présentes CGU à tout moment pour les adapter aux évolutions du site ou aux exigences légales.

**Droit Applicable**  
Les présentes CGU sont régies par le droit suisse. Tout litige sera de la compétence exclusive des tribunaux suisses.

`