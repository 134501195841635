import { article as quisommesnous } from "./content/qui-sommes-nous.js";

const img_default = "pexels-thirdman-8926648.webp";


export const articles = {
  home: {
    img: img_default,
  },
  quisommesnous: {
    image: "pexels-mccutcheon-1449934.webp",
    content: quisommesnous,
    updateDate: "20.11.2024",
  },
};
