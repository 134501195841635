export const content = `# Politique de Cookies

Ce site utilise des cookies pour améliorer l'expérience utilisateur et sécuriser les formulaires grâce à Google reCAPTCHA.

**Cookies Utilisés**  
- **Cookies techniques** : nécessaires pour le bon fonctionnement du site.
- **Cookies de sécurité** : utilisés par Google reCAPTCHA pour prévenir le spam.

**Paramétrage des Cookies**  
Vous pouvez configurer votre navigateur pour bloquer certains cookies. Cependant, cela pourrait limiter certaines fonctionnalités, notamment la soumission de formulaires.

Pour plus d’informations, veuillez consulter la [Politique de confidentialité de Google](https://policies.google.com/privacy).
`