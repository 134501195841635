export const content = `# Politique de Confidentialité

**Collecte des Données Personnelles**  
Nous collectons et traitons des données personnelles par le biais de notre site pour gérer les demandes de contact et les inscriptions.

**Finalité des Traitements**  
Ces données sont collectées dans le but de :  
- Permettre aux responsables de la garderie de répondre aux demandes de contact et d’inscription.
- Améliorer les services offerts sur le site.

**Sécurité et Confidentialité**  
Nous mettons en œuvre des mesures de sécurité pour protéger les données personnelles contre l'accès non autorisé, l'altération ou la divulgation. Nous utilisons Google reCAPTCHA pour assurer la sécurité de nos formulaires et protéger contre les spams.

**Destinataires des Données**  
Les données personnelles ne sont transmises qu’aux responsables de la garderie et ne sont pas partagées avec des tiers sans votre consentement.

**Durée de Conservation des Données**  
Les données collectées sont conservées pendant la durée nécessaire pour répondre aux demandes et gérer les inscriptions, puis supprimées.

**Vos Droits**  
Conformément à la Loi fédérale sur la protection des données (LPD), vous disposez d'un droit d'accès, de rectification, d'opposition, de suppression et de portabilité des données personnelles vous concernant. Pour exercer ces droits, veuillez nous contacter à <span class="email"></span>.

**Google reCAPTCHA**  
Ce site utilise Google reCAPTCHA pour lutter contre les spams. Ce service est soumis à la [Politique de confidentialité de Google](https://policies.google.com/privacy) et aux [Conditions d'utilisation](https://policies.google.com/terms).

`