import { content as confident } from "./legal/confident.js";
import { content as cookie } from "./legal/cookie.js";
import { content as mention } from "./legal/mention.js";

import { content as cgu } from "./legal/cgu.js";

const img_legal = "books.webp";

export const legaux = {
    cgu: {
      image: img_legal,
      content: cgu,
      updateDate: "15.11.2024",
    },
    confident: {
      image: img_legal,
      content: confident,
      updateDate: "15.11.2024",
    },
    cookie: {
      image: img_legal,
      content: cookie,
      updateDate: "15.11.2024",
    },
    mention: {
      image: img_legal,
      content: mention,
      updateDate: "15.11.2024",
    },
  };
  
  