export const content = `# Mentions Légales

**Éditeur du Site**  
Nom de l’entreprise : <span class="name"></span>  
Adresse : <span class="address"></span>  
Email : <span class="email"></span>

**Hébergeur**  
Nom de l’hébergeur : Netlify  
Adresse de l’hébergeur : 2325 3rd Street, Suite 296, San Francisco, CA 94107, USA  

**Propriété Intellectuelle**  
Tous les contenus présents sur ce site sont la propriété exclusive de la **<span class="name"></span>** et sont protégés par le droit suisse ainsi que par les lois internationales sur la propriété intellectuelle.
`